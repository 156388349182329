import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {AppEventService} from '../../../services/app.event.service';
import {AppSettingsService} from '../../../services/app.app_settings.service';
import {AppUserService} from "../../../services/app.user.service";
import {AppAsyncTimeout} from "../../../helpers/app.asyncTimeout";
import {AppLieferkundeService} from "../../../services/app.lieferkunde.service";

@Injectable({providedIn: 'root'})
export class FavMenuButtonService {
  favIds = new BehaviorSubject([]);

  constructor(private http: HttpClient, private sett: AppSettingsService,
              private usvc: AppUserService, private lksvc: AppLieferkundeService) {
    this.lksvc.current_lieferkunde$.subscribe(() => {
      if (this.usvc.isLoggedin) {
        this.load();
      }
    })
  }

  rem(id: string) {
    const ids = this.favIds.value;
    const idx = ids.indexOf(id);
    ids.splice(idx, 1);
    this.save(ids);
  }

  add(id: string) {
    const ids = this.favIds.value;
    ids.push(id);
    this.save(ids);
  }

  private save(ids) {
    this.sett.setUserSettingValue('GUI_FavMenuButtons', ids).subscribe();
    this.favIds.next(ids);
  }

  reset() {
    this.sett.clearUserSettingValue('GUI_FavMenuButtons').subscribe(r => {
      this.load();
    });
  }

  private load() {
    this.sett.getUserSettingValue('GUI_FavMenuButtons').subscribe(ids => {
      this.favIds.next(ids);
    });
  }

}
