<div class="logincontentinner">
    <div>
      <div class="loginlogobox">
        <img class="logo" src="{{domaincfg.logofilename}}"/>
      </div>


      <div class="loginbox">
        <div class="bar">Login {{domaincfg.pagetitle}}</div>


        <div class="welcometext" *ngIf="domaincfg.login_welcome_message">{{domaincfg.login_welcome_message}}</div>


        <form #f="ngForm" (ngSubmit)="f.form.valid && login()" autocomplete="on" name="form" novalidate>
          <mat-form-field [ngClass]="{ 'has-error': f.submitted && (!username.valid || login_error)  }"  appearance="outline">
            <mat-label>Benutzername</mat-label>
            <input #username="ngModel" [(ngModel)]="model.username" autocomplete="on" matInput name="username" email
                   required value="" (keydown)="resetLogin()">
            <div *ngIf="f.submitted && !username.valid" class="help-block">Benutzername wird benötigt!</div>

          </mat-form-field>

          <div style="height: 100px; margin-top: 20px">

            <mat-form-field [ngClass]="{ 'has-error': f.submitted && (!password.valid || login_error) }"  appearance="outline">
              <mat-label>Passwort</mat-label>
              <input #password="ngModel" [(ngModel)]="model.password" autocomplete="on" matInput minlength="8"
                     name="password"
                     required type="password" value="" (keydown)="resetLogin()">
              <div *ngIf="(password.dirty || password.touched) && password.invalid && password.errors.required"
                   class="help-block">Passwort wird benötigt!
              </div>
              <div *ngIf="(password.dirty || password.touched) && password.invalid && password.errors.minlength"
                   class="help-block">Passwort muss 8 Zeichen lang sein!
              </div>


            </mat-form-field>

            <div *ngIf="login_error"
                 class="help-block" style="color: red">{{login_error}}
            </div>

          </div>


          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary" mat-flat-button>
              <svg-icon>login</svg-icon>
              Anmelden
            </button>
            <div style="height: 22px; margin-top: 10px; margin-bottom: 20px">
              <img [class.hidden]="!loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
            </div>

            <a routerLink="/reset-password">
              Passwort zurücksetzen
            </a>
          </div>

          <div style="text-align: center">
            <hr style="max-width: 200px">
          </div>

          <div>
            <button mat-raised-button routerLink="/register">
              <svg-icon>registrieren</svg-icon>
              Registrieren
            </button>
          </div>
        </form>
      </div>
    </div>
</div>
