<ng-container [ngTemplateOutlet]="
fieldname=='bezeichnung'?template_bezeichnung:
fieldname=='minablauf'?template_minablauf:
fieldname== 'darreichungsform'?template_darreichungsform:
fieldname=='Zulassungsnummer'?template_Zulassungsnummer:
fieldname=='hersteller'?template_hersteller:
fieldname=='artikelnummer'?template_artikelnummer:
fieldname=='pzn'?template_pzn:
fieldname=='einkaufspreis'?template_einkaufspreis:
fieldname=='verkaufspreis'?template_verkaufspreis:
fieldname=='mwst'?template_mwst:
fieldname=='rezeptzeichen'?template_rezeptzeichen:
fieldname=='istarzneimittel'?template_istarzneimittel:
fieldname=='status'?template_status:
fieldname=='aktionen_button'?template_aktionen_button:
fieldname=='logo1'?template_logo1:
fieldname=='logo2'?template_logo2:
fieldname=='Beschreibung'?template_Beschreibung:
fieldname=='avp'?template_avp:
fieldname=='Breite'?template_Breite:
fieldname=='Laenge'?template_Laenge:
fieldname=='Hoehe'?template_Hoehe:
fieldname=='Gewicht'?template_Gewicht:
fieldname=='view'?template_view:
fieldname=='Kurzbeschreibung'?template_Kurzbeschreibung:
fieldname=='note'?template_note:
template_default
"></ng-container>
<ng-template #template_bezeichnung>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
        <span>{{ artikel.label }}</span>
        <span *ngIf="(wksvc.artnr_wk_map2$|async)?.includes(artikel.ArtikelNummer.toString()) && isInOtherWarenkorb()"
              style="padding-left: 5px;">
          <svg-icon matTooltip="Bereits in einem Warenkorb" size="14"
                    style="color: green; height: 14px; width: 14px; font-size: 14px">warenkorb</svg-icon>
        </span>
      </span>
</ng-template>


<ng-template #template_minablauf>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
        {{ artikel.MinAblauf }}
      </span>
</ng-template>

<ng-template #template_darreichungsform>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
        {{ artikel.Darreichungsform }}
      </span>
</ng-template>

<ng-template #template_Zulassungsnummer>
       <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
             [style.white-space]="nowrap ? 'nowrap': ''">
        {{ artikel.ArtikelZulassungsnummer|RemoveLeadingZero }}
       </span>
</ng-template>

<ng-template #template_logo1>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <lightbox-image [image]="artikel.logo1" *ngIf="mode == 'table'" thumb_max_height="50"></lightbox-image>
      <lightbox-image [image]="artikel.logo1" *ngIf="mode != 'table'" thumb_max_height="250"></lightbox-image>
      </span>
</ng-template>

<ng-template #template_logo2>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <lightbox-image [image]="artikel.logo2" *ngIf="mode == 'table'" thumb_max_height="50"></lightbox-image>
      <lightbox-image [image]="artikel.logo2" *ngIf="mode != 'table'" thumb_max_height="250"></lightbox-image>
      </span>
</ng-template>

<ng-template #template_pzn>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <span *ngIf="artikel.PZN">
      {{ artikel.PZN|PhZNr }}{{ append_if_not_empty }}
      </span>
      </span>
</ng-template>

<ng-template #template_avp>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel.Kundenverkaufspreis|NiceNumber }}<ng-container *ngIf="artikel.Kundenverkaufspreis !== null"> &euro;</ng-container>
      </span>
</ng-template>

<ng-template #template_verkaufspreis>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel.Kundenverkaufspreis|NiceNumber }}<ng-container *ngIf="artikel.Kundenverkaufspreis !== null"> &euro;</ng-container>
      </span>
</ng-template>

<ng-template #template_view>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel.view.label }}
      </span>
</ng-template>

<ng-template #template_mwst>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel.Mehrwertsteuersatz }} %
      </span>
</ng-template>

<ng-template #template_einkaufspreis>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <ng-container *ngIf="(show_uvp|async); else ekpreisoder">
        <ng-container *ngIf="artikel.UVPDiffProzent > 0; else ekpreisoder">
          <table>
            <tr style="background-color: transparent">
              <td rowspan="2" style="vertical-align: middle; border: 0; padding: 5px; padding-left: 20px">
                <span style="font-size: 18px; color: orange; font-weight: bold;">
                  {{ artikel.Kundeneinkaufspreis|NiceNumber }}<ng-container *ngIf="artikel.Kundeneinkaufspreis !== null"> &euro;</ng-container>
                </span>
              </td>
              <td style="border: 0; padding: 0">
                <div class="line-through" style="font-size: 16px; color: grey">
                  {{ artikel.PreisLtPreiscodeMitPotentiellAufschlag|NiceNumber }}<ng-container *ngIf="artikel.PreisLtPreiscodeMitPotentiellAufschlag !== null"> &euro;</ng-container>
                </div>
              </td>
            </tr>
            <tr style="background-color: transparent">
              <td style="border: 0; padding: 0; text-align: right">
                <div class="uvpdiffbox">
                  -{{ artikel.UVPDiffProzent }} %
                </div>
              </td>
            </tr>
          </table>
        </ng-container>
      </ng-container>
      <ng-template #ekpreisoder>
        {{ artikel.Kundeneinkaufspreis|NiceNumber }}<ng-container *ngIf="artikel.Kundeneinkaufspreis !== null"> &euro;</ng-container>
      </ng-template>
      </span>
</ng-template>

<ng-template #template_rezeptzeichen>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel.RezeptZeichen }}
      </span>
</ng-template>

<ng-template #template_istarzneimittel>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <span *ngIf="artikel.IstArzneimittel" matTooltip="Ist ein Arzneimittel">
        <svg-icon>success</svg-icon>
      </span>
      </span>
</ng-template>

<ng-template #template_status>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <artikel-status-icon [artikel]="artikel"></artikel-status-icon>
      </span>
</ng-template>

<ng-template #template_aktionen_button>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <artikel-aktionen-button
        *ngIf="view && artikel"
        [artikel]="artikel"
        [view]="view"
        [viewcomp]="viewcomp"
      ></artikel-aktionen-button>
      </span>
</ng-template>

<ng-template #template_hersteller>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel.Erzeuger.adresszeile1 }}
      </span>
</ng-template>

<ng-template #template_note>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}" style="vertical-align: middle"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <artikel-note [artikel]="artikel"></artikel-note>
      </span>
</ng-template>

<ng-template #template_Laenge>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel[fieldname]|unitconverter_mm }}
      </span>
</ng-template>

<ng-template #template_Breite>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel[fieldname]|unitconverter_mm }}
      </span>
</ng-template>

<ng-template #template_Hoehe>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel[fieldname]|unitconverter_mm }}
      </span>
</ng-template>

<ng-template #template_Gewicht>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel[fieldname]|unitconverter_g }}
      </span>
</ng-template>

<ng-template #template_artikelnummer>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      {{ artikel['ArtikelNummer'] }}
      </span>
</ng-template>

<ng-template #template_Kurzbeschreibung>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
        <span [innerHTML]="artikel['Kurzbeschreibung']|nl2br"></span>
      </span>
</ng-template>

<ng-template #template_Beschreibung>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <span [innerHTML]="artikel['Beschreibung']|nl2br"></span>
      </span>
</ng-template>

<ng-template #template_default>
      <span [class.sgarticle]="artikel.RezeptZeichen == 'SG'" class="field_{{fieldname}}"
            [style.white-space]="nowrap ? 'nowrap': ''">
      <span *ngIf="artikel[fieldname]">{{ artikel[fieldname] }}</span>
      <span *ngIf="artikel[fieldname] === undefined">missing {{ fieldname }}</span>
      </span>
</ng-template>
