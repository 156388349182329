<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  <div [innerHTML]="text|raw" style="padding: 5px"></div>

  <div *ngFor="let f of fields; let last = last" style="padding: 5px">
    <mat-form-field (keydown.enter)="fieldEnterKeyDown(f)" appearance="outline" style="width: 300px">
      <mat-label>{{f.label}}</mat-label>
      <input *ngIf="!f.is_textarea && !f.choices" [(ngModel)]="f.value" matInput>
      <mat-select *ngIf="!f.is_textarea && f.choices" [(ngModel)]="f.value">
        <mat-option *ngFor="let opt of f.choices" [value]="opt.id">
          {{opt.label}}
        </mat-option>
      </mat-select>

      <textarea *ngIf="f.is_textarea" [(ngModel)]="f.value"
                matInput></textarea>
    </mat-form-field>
  </div>

</div>
<div align="center" mat-dialog-actions>
  <button (click)="click(button)" *ngFor="let button of buttons" class="mat-raised-button {{button.class}}"
          mat-raised-button>
    {{button.label}}
  </button>
</div>
