import {DomSanitizer} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';
import {AppConfigService} from '../../../services/app.config.service';

@Pipe({name: 'NiceNumber', pure: false})
export class SharedPipeNicenumberPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private cfg: AppConfigService) {
  }

  transform(value: any, no_comma_null: boolean = false, commaMax = 2): any {
    if (value === null) {
      return '-';
    }
    if (isNaN(value) && value) {
      value = value.replace(',', '.');
    }

    let ret = this.niceNumber(value, no_comma_null, commaMax);

    if (ret == 'NaN') {
      ret = this.niceNumber(0, no_comma_null, commaMax);
    }

    return ret;
  }

  niceNumber(val, no_comma_null: boolean = false, commaMax) {
    let myreturn = new Intl.NumberFormat('de', {
      minimumFractionDigits: 2,
      maximumFractionDigits: commaMax,
    }).format(Number(val));

    if (no_comma_null) {
      myreturn = myreturn.replace(',00', '');
    }

    return myreturn;
  }

}
