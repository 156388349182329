import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {
  MatPaginatorIntl,
  MatPaginatorModule
} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {QuillModule} from 'ngx-quill';
import {getGermanPaginatorIntl} from './translations/german-paginator-intl';
import {SharedPipeRawhtmlPipe} from './pipe/shared.pipe.rawhtml.pipe';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {SharedBackbuttonComponent} from './components/backbutton/shared.backbutton.component';
import {SharedDialogsConfirmationComponent} from './dialogs/confirmation/shared.dialogs.confirmation.component';
import {SharedPipeNicenumberPipe} from './pipe/shared.pipe.nicenumber.pipe';
import {SharedPipeNl2brPipePipe} from './pipe/shared.pipe.nl2br.pipe';
import {SharedSecondshumanreadablePipe} from './pipe/shared.secondshumanreadable.pipe';
import {
  SharedDurationDatePickerComponent
} from './components/duration-date-picker/shared.duration-date-picker.component';
import {SharedA2StringBooleanComponent} from './components/a2-string-boolean/shared.a2-string-boolean.component';
import {SharedInfoBoxComponent} from './components/info-box/shared.info-box.component';
import {SharedSectionTitleComponent} from './components/section-title/shared.section-title.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {OWL_DATE_TIME_LOCALE, OwlDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {OwlMomentDateTimeModule} from '@danielmoncada/angular-datetime-picker-moment-adapter';
import {MatTabsModule} from '@angular/material/tabs';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SimplebarAngularModule} from 'simplebar-angular';
import {SharedLkndnrToLieferkundeLabelPipe} from './pipe/shared.lkndnr-to-lieferkunde-label.pipe';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SharedSafePipe} from './pipe/shared.safe.pipe';
import {SharedPermissionBlockComponent} from './components/permission-block/shared.permission-block.component';
import {SharedSvgIconComponent} from './components/svg-icon/shared.svg-icon.component';
import {SharedSvgIconSelectComponent} from './components/svg-icon-select/shared.svg-icon-select.component';
import {MAT_COLOR_FORMATS, MatColorFormats, NgxMatColorPickerModule} from '@angular-material-components/color-picker';
import {
  SharedSectionTitleButtonsComponent
} from './components/section-title-button/shared.section-title-buttons.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {DigitOnlyDirective} from './directives/digit-only.directive';
import {SharedUnitconverterMmPipe} from './pipe/shared.unitconverter-mm.pipe';
import {SharedUnitconverterGPipe} from './pipe/shared.unitconverter-g.pipe';
import {SharedCollapseBoxComponent} from './components/collapse-box/shared.collapse-box.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {SharedContactButtonComponent} from './components/contact-button/shared.contact-button.component';
import {SharedDialogsContactFormComponent} from './dialogs/contact-form/shared.dialogs.contact-form.component';
import {MatListModule} from '@angular/material/list';
import {SharedPipePhznrPipe} from './pipe/shared.pipe.phznr.pipe';
import {SharedSearchBoxComponent} from './components/search-box/shared.search-box.component';
import {SharedStarViewComponent} from './components/star-view/shared.star-view.component';
import {SharedPipeFixumlautePipe} from './pipe/shared.pipe.fixumlaute.pipe';
import {SharedFormErrorComponent} from './components/form-error/shared.form-error.component';
import {
  AppDifferentialLkBannerComponent
} from './components/differential-lk-banner/app.differential-lk-banner.component';
import {SharedFileBrowserComponent} from './components/file-browser/shared.file-browser.component';
import {MatTreeModule} from '@angular/material/tree';
import {SharedFilePickerComponent} from './components/file-picker/shared.file-picker.component';
import {
  SharedDialogsFilePickerDialogComponent
} from './dialogs/file-picker-dialog/shared.dialogs.file-picker-dialog.component';
import {SharedMengeInputComponent} from './components/menge-input/shared.menge-input.component';
import {SharedFeatureBlockComponent} from './components/feature-block/shared.feature-block.component';
import {SharedSettingsBoxComponent} from './components/settings-box/shared.settings-box.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {SharedSettingsUserIpsComponent} from "./components/settings-user-ips/shared.settings-user-ips.component";
import {
  SharedPermissionTableElementComponent
} from "./components/permission-table-element/shared.permission-table-element.component";
import {LightboxModule} from "ngx-lightbox";
import {SharedLightboxImageComponent} from "./components/lightbox-image/shared.lightbox-image.component";
import {SharedViewFieldSelectorComponent} from "./components/view-field-selector/shared.view-field-selector.component";
import {
  SharedViewFieldLabelRendererComponent
} from "./components/view-field-label-renderer/shared.view-field-label-renderer.component";
import {SharedArtikelLabelPipe} from "./pipe/shared.artikel-label.pipe";
import {SharedPipeRemoveLeadingZeroPipe} from "./pipe/shared.pipe.remove-leading-zero.pipe";
import {PreventNumberScrollDirective} from "./directives/prevent-number-scroll.directive";
import {ZXingScannerModule} from "@zxing/ngx-scanner";
import {SharedScannerComponent} from "./components/scanner/shared.scanner.component";
import {SharedPipeTrimPipePipe} from "./pipe/shared.pipe.trim.pipe";
import {SharedNiceNumberInputComponent} from "./components/nice-number-input/shared.nice-number-input.component";
import {NgxFilesizeModule} from "ngx-filesize";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {
  SharedArticleAutocompleteComponent
} from "./components/article-autocomplete/shared.article-autocomplete.component";
import {SharedPipeNiceAuftragsstatus} from "./pipe/shared.pipe.nice-auftragsstatus.pipe";
import {SharedIntervalInputComponent} from "./components/interval-input/shared.interval-input.component";
import {SharedFormFileInputComponent} from "./components/form-file-input/shared.form-file-input.component";
import {SharedDirectiveDndDirective} from "./directives/shared.directive.dnd.directive";
import {SharedDialogsContentViewerComponent} from "./dialogs/content-viewer/shared.dialogs.content-viewer.component";
import {PdfJsViewerModule} from "ng2-pdfjs-viewer";
import {
  SharedDialogsFileUploadTokenComponent
} from "./dialogs/file-upload-token/shared.dialogs.file-upload-token.component";
import {QRCodeModule} from "angularx-qrcode";
import {SharedFileTypeIconComponent} from "./components/file-type-icon/shared.file-type-icon.component";
import {ClipboardModule} from "@angular/cdk/clipboard";

/*export const MY_MOMENT_FORMATS = {
    parseInput: 'l LT',
    fullPickerInput: 'l LT',
    datePickerInput: 'l',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};*/

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const CUSTOM_MAT_COLOR_FORMATS: MatColorFormats = {
  display: {
    colorInput: 'hex'
  }
};

@NgModule({
  declarations: [
    SharedArtikelLabelPipe,
    SharedFileBrowserComponent,
    AppDifferentialLkBannerComponent,
    SharedPermissionBlockComponent,
    SharedFormErrorComponent,
    SharedPipeRawhtmlPipe,
    SharedBackbuttonComponent,
    SharedDialogsConfirmationComponent,
    SharedPipeNicenumberPipe,
    SharedPipeNl2brPipePipe,
    SharedSecondshumanreadablePipe,
    SharedDurationDatePickerComponent,
    SharedA2StringBooleanComponent,
    SharedInfoBoxComponent,
    SharedSectionTitleComponent,
    SharedLkndnrToLieferkundeLabelPipe,
    SharedSafePipe,
    SharedSvgIconComponent,
    SharedSvgIconSelectComponent,
    SharedSectionTitleButtonsComponent,
    DigitOnlyDirective,
    SharedUnitconverterMmPipe,
    SharedUnitconverterGPipe,
    SharedCollapseBoxComponent,
    SharedContactButtonComponent,
    SharedDialogsContactFormComponent,
    SharedPipePhznrPipe,
    SharedSearchBoxComponent,
    SharedStarViewComponent,
    SharedPipeFixumlautePipe,
    SharedFilePickerComponent,
    SharedDialogsFilePickerDialogComponent,
    SharedMengeInputComponent,
    SharedFeatureBlockComponent,
    SharedSettingsBoxComponent,
    SharedSettingsUserIpsComponent,
    SharedPermissionTableElementComponent,
    SharedLightboxImageComponent,
    SharedViewFieldSelectorComponent,
    SharedViewFieldLabelRendererComponent,
    SharedPipeRemoveLeadingZeroPipe,
    PreventNumberScrollDirective,
    SharedScannerComponent,
    SharedPipeTrimPipePipe,
    SharedNiceNumberInputComponent,
    SharedArticleAutocompleteComponent,
    SharedPipeNiceAuftragsstatus,
    SharedIntervalInputComponent,
    SharedFormFileInputComponent,
    SharedDirectiveDndDirective,
    SharedDialogsContentViewerComponent,
    SharedDialogsFileUploadTokenComponent,
    SharedFileTypeIconComponent,
  ],
  imports: [
    QuillModule.forRoot(),
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    MatChipsModule,
    MatBadgeModule,
    MatSelectModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    MatTabsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    SimplebarAngularModule,
    MatDatepickerModule,
    NgxMatColorPickerModule,
    MatSidenavModule,
    MatBottomSheetModule,
    MatListModule,
    MatTreeModule,
    MatSlideToggleModule,
    LightboxModule,
    ZXingScannerModule,
    NgxFilesizeModule,
    MatAutocompleteModule,
    PdfJsViewerModule,
    QRCodeModule,
    ClipboardModule,
  ],
  exports: [
    // DECLARATIONS
    SharedDialogsFileUploadTokenComponent,
    SharedDialogsContentViewerComponent,
    SharedFormFileInputComponent,
    SharedIntervalInputComponent,
    SharedNiceNumberInputComponent,
    SharedViewFieldLabelRendererComponent,
    SharedViewFieldSelectorComponent,
    SharedLightboxImageComponent,
    SharedPermissionTableElementComponent,
    SharedSettingsUserIpsComponent,
    SharedSettingsBoxComponent,
    SharedFeatureBlockComponent,
    SharedFilePickerComponent,
    SharedFileBrowserComponent,
    AppDifferentialLkBannerComponent,
    SharedSearchBoxComponent,
    SharedContactButtonComponent,
    DigitOnlyDirective,
    PreventNumberScrollDirective,
    SharedPermissionBlockComponent,
    SharedFormErrorComponent,
    SharedPipeRawhtmlPipe,
    SharedArtikelLabelPipe,
    SharedBackbuttonComponent,
    SharedDialogsConfirmationComponent,
    SharedPipeNicenumberPipe,
    SharedPipeNl2brPipePipe,
    SharedSecondshumanreadablePipe,
    SharedDurationDatePickerComponent,
    SharedA2StringBooleanComponent,
    SharedInfoBoxComponent,
    SharedSectionTitleComponent,
    SharedSafePipe,
    SharedSvgIconComponent,
    SharedSvgIconSelectComponent,
    SharedSectionTitleButtonsComponent,
    SharedCollapseBoxComponent,
    SharedStarViewComponent,
    SharedMengeInputComponent,
    SharedScannerComponent,
    SharedArticleAutocompleteComponent,

    // IMPORTS
    ClipboardModule,
    NgxFilesizeModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatSidenavModule,
    SimplebarAngularModule,
    QuillModule,
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatProgressBarModule,
    FormsModule,
    MatChipsModule,
    MatBadgeModule,
    MatSelectModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    MatTabsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgxMatColorPickerModule,
    ZXingScannerModule,
    MatAutocompleteModule,
    PdfJsViewerModule,
    QRCodeModule,

    // PROVIDERS
    SharedLkndnrToLieferkundeLabelPipe,
    SharedSecondshumanreadablePipe,
    SharedPipeNl2brPipePipe,
    SharedPipeRawhtmlPipe,
    SharedPipeNicenumberPipe,
    SharedUnitconverterMmPipe,
    SharedUnitconverterGPipe,
    SharedPipePhznrPipe,
    SharedPipeFixumlautePipe,
    SharedPipeRemoveLeadingZeroPipe,
    SharedPipeTrimPipePipe,
    SharedPipeNiceAuftragsstatus,
    SharedDirectiveDndDirective,
  ],
  providers: [
    SharedDirectiveDndDirective,
    SharedPipeTrimPipePipe,
    SharedPipeRemoveLeadingZeroPipe,
    SharedPipeFixumlautePipe,
    SharedPipePhznrPipe,
    SharedUnitconverterGPipe,
    SharedUnitconverterMmPipe,
    SharedSecondshumanreadablePipe,
    SharedPipeNl2brPipePipe,
    SharedPipeRawhtmlPipe,
    SharedPipeNicenumberPipe,
    SharedLkndnrToLieferkundeLabelPipe,
    SharedSafePipe,
    SharedPipeNiceAuftragsstatus,
    {provide: MAT_COLOR_FORMATS, useValue: CUSTOM_MAT_COLOR_FORMATS},
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'de'},
    {
      provide: MatPaginatorIntl,
      useValue: getGermanPaginatorIntl()
    },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: LOCALE_ID, useValue: 'de-DE'},
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } }
  ],
})
export class SharedModule {
}
