import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {AppDialogsService} from './app.dialogs.service';
import {BehaviorSubject, concat, interval} from 'rxjs';
import {first} from 'rxjs/operators';
import {AppConfigService} from './app.config.service';
import {environment} from '../../environments/environment';
import {AppAsyncTimeout} from "../helpers/app.asyncTimeout";

@Injectable({providedIn: 'root'})
export class AppUpdateService {

  updateAvailable = new BehaviorSubject<boolean>(false);

  constructor(appRef: ApplicationRef, public updates: SwUpdate, private dsvc: AppDialogsService, private cfg: AppConfigService) {
    updates.versionUpdates.subscribe((event: VersionEvent) => {
      if (event.type == 'VERSION_READY') {
        this.triggerUpdateIsAvailable();
      }
    });

    updates.unrecoverable.subscribe(event => {
      this.dsvc.info(
        'error',
        'Update Fehlgeschlagen!',
        'Applikation startet neu!');
      AppAsyncTimeout.setTimeout(() => {
        document.location.reload();
      }, 1500);
    });

    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everyEvery5Seconds$ = interval(5 * 1000);
    const checkOnceAppIsStable$ = concat(appIsStable$, everyEvery5Seconds$);

    checkOnceAppIsStable$.subscribe(() => this.checkForUpdates());
  }

  checkForUpdates() {
    if (!this.cfg.isDebug() && this.updates.isEnabled) {
      this.updates.checkForUpdate();
    }
  }

  triggerUpdateIsAvailable() {
    this.updateAvailable.next(true);
  }

  installUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
