import {CallCenter} from './call-center';

export class Lieferkunde {
  KundeNr: number;
  LieferkundeNr: number|string;
  B2C: boolean;
  Anrede: string;
  Titel: string;
  Vorname: string;
  A2KndNrDefault: string;
  A2KndNr: string;
  Callcenter: CallCenter;
  Nachname: string;
  Strasse: string;
  Plz: string;
  Ort: string;
  Staat: string;
  Telefon: string;
  Email: string;

  a3KundeNr: number;
  a3KundeLabel: string;
}
