<div style="display: inline-block" class="warenkorbbuttonbox">
  <button (click)="addToPrimaryWarenkorb()" (contextmenu)="showMenu($event)"
          *ngIf="(wksvc.primary_warenkorb$|async)" class="tut_shopview_artwk"
          [disabled]="disabled || (entry.freimenge == 0 && entry.menge == 0 && entry.aktionsrabattmenge == 0) || !entry.valid || (buttonDisabled|async)"
          mat-button
          matTooltip="Zum Warenkorb '{{(wksvc.primary_warenkorb$|async)?.label}}' hinzufügen. (R-Klick für Auswahl)">
    <svg-icon
      color="{{(disabled || (entry.freimenge == 0 && entry.menge == 0 && entry.aktionsrabattmenge == 0)  || !entry.valid ) ? 'grey' : 'green'}}">
      in-warenkorb
    </svg-icon>
  </button>
</div>

<div style="display: inline-block" class="warenkorbbuttonbox">
  <permission-block [oneOfRoles]="['Vertreter']">
    <button (click)="addToWarenkorbDialog()" class="tut_shopview_artwkvertr"
            (contextmenu)="$event.preventDefault()"
            *ngIf="((vertreterconfig|async)?.DomainVertreterUseDialog && (vertreterconfig|async)?.HasFields)"
            [disabled]="disabled || (entry.freimenge == 0 && entry.menge == 0 && entry.aktionsrabattmenge == 0) || !entry.valid || (buttonDisabled|async)"
            mat-icon-button
            matTooltip="Mit Kondition hinzufügen. (R-Klick für Auswahl)">
      <svg-icon color="orange">in-warenkorb</svg-icon>
    </button>
  </permission-block>
</div>
